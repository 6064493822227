.homepage-intro-scroll {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
}

.homepage-intro-scroll-text {
  margin-top: 50px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;
  color: #3b3b3b;
  animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.homepage-intro-scroll-mouse {
  background: #b8bac3 linear-gradient(transparent 0%, transparent 50%, #3b3b3b 50%, #3b3b3b 100%);
  position: relative;
  width: 52px;
  height: 88px;
  background-size: 100% 100%;
  border-radius: 100px;
  background-size: 225%;
  animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}
.homepage-intro-scroll-mouse:before, .homepage-intro-scroll-mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.homepage-intro-scroll-mouse:before {
  width: 46px;
  height: 82px;
  background-color: #fff;
  border-radius: 100px;
}
.homepage-intro-scroll-mouse:after {
  background-color: #3b3b3b;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  animation: trackBallSlide 5s linear infinite;
}

@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #b8bac3;
  }
  29.99% {
    background-color: #3b3b3b;
    background-position: 0% 0%;
  }
  30% {
    background-color: #b8bac3;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #b8bac3;
  }
  59% {
    background-color: #3b3b3b;
    background-position: 0% 0%;
  }
  60% {
    background-color: #b8bac3;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #b8bac3;
  }
  90%, 100% {
    background-color: #3b3b3b;
  }
}
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
}
@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(8px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(8px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes nudgeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes colorText {
  21% {
    color: #b8bac3;
  }
  30% {
    color: #3b3b3b;
  }
  51% {
    color: #b8bac3;
  }
  60% {
    color: #3b3b3b;
  }
  81% {
    color: #b8bac3;
  }
  90% {
    color: #3b3b3b;
  }
}

@keyframes trackBallSlideMobile {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-10px);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-10px);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-10px);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
}
@keyframes nudgeMouseMobile {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(8px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(8px);
  }
  90% {
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
	.homepage-intro-scroll-text {
	  margin-top: 25px;
	  letter-spacing: 2px;
	}
	.homepage-intro-scroll-mouse {
	  width: 28px;
	  height: 48px;
	  animation: colorSlide 5s linear infinite, nudgeMouseMobile 5s ease-out infinite;
	}
	.homepage-intro-scroll-mouse:before {
	  width: 24px;
	  height: 44px;
	}
	.homepage-intro-scroll-mouse:after {
	  width: 5px;
	  height: 5px;
	  animation: trackBallSlideMobile 5s linear infinite;
	}
}