/*!
 * This file is part of Contao.
 *
 * (c) Leo Feyer
 *
 * @license LGPL-3.0-or-later
 */

/**
 * Standardize some basic elements
 */
body, form, figure {
    margin: 0;
    padding: 0;
}

img {
    border: 0;
}

header, footer, nav, section, aside, article, figure, figcaption {
    display: block;
}

/**
 * Fix some font issues
 */
body {
    font-size: 100.01%;
}

select, input, textarea {
    font-size: 99%;
}

/**
 * Fix some positioning issues
 */
#container, .inside {
    position: relative;
}

/**
 * Holy grail CSS layout
 *
 * @see http://www.alistapart.com/articles/holygrail
 */
#main, #left, #right {
    float: left;
    position: relative;
}

#main {
    width: 100%;
}

#left {
    margin-left: -100%;
}

#right {
    margin-right: -100%;
}

#footer {
    clear: both;
}

#main .inside {
    min-height: 1px; /* see #4893 */
}

/**
 * Format the Contao image galleries (now rendered as unordered lists)
 */
.content-gallery > ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
}

.content-gallery > ul li {
    float: left;
}

.content-gallery > ul li.col_first {
    clear: left;
}

/**
 * Float classes (see #6851)
 */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/**
 * Clear floats
 */
.block {
    overflow: hidden;
}

.clear, #clear {
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
    clear: both;
}

/**
 * Hide invisible elements
 */
.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/**
 * Custom layout sections
 */
.custom {
    display: block;
}

#container:after, .custom:after {
    content: "";
    display: table;
    clear: both;
}
