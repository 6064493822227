/* desktop.css */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    min-height: 100%;
    position: relative
}

body {
    margin-bottom: 212px;
    background-color: #f4f3f4;
    font: 14px/18px "Open Sans", Arial, sans-serif;
    color: #26262c
}

figure {
    margin: 0;
    padding: 0
}

h1, h2, h3, h4, h5, h6, p, ul {
    margin-top: 0;
    margin-bottom: 18px
}

h1 {
    margin-bottom: 12px;
    font: 48px/65px Oswald, Arial, sans-serif;
    font-weight: bold;
    color: #26262c;
    text-transform: uppercase
}

h2 {
    font: 22px/28px "Open Sans", Arial, sans-serif;
    font-weight: 300
}

h3, h4, h5, h6 {
    font: 24px/65px Oswald, Arial, sans-serif;
    font-weight: normal
}

img {
    vertical-align: middle
}

a {
    text-decoration: none;
    color: #26262c
}

a:hover {
    text-decoration: underline
}

#wrapper {
    padding-top: 100px
}

#header {
    width: 100%;
    height: 100px;
    left: 0;
    top: 0;
    position: fixed;
    background-color: rgba(255, 255, 255, .5);
    z-index: 9;
    -webkit-transition: background-color ease-out .5s;
    -moz-transition: background-color ease-out .5s;
    -o-transition: background-color ease-out .5s;
    transition: background-color ease-out .5s
}

#header.solid, #header:hover {
    background-color: #fff
}

#header .inside {
    width: 992px;
    margin-right: auto;
    margin-left: auto
}

#logo {
    left: 471px;
    top: 27px;
    position: absolute
}

#header .mod_navigation {
    width: 608px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 43px;
    padding-right: 0;
    padding-left: 0
}

#header .mod_navigation ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

#header .mod_navigation ul li {
    float: left;
    margin: 0;
    padding: 0
}

#header .mod_navigation ul li:nth-child(1), #header .mod_navigation ul li:nth-child(3) {
    margin-right: 60px
}

#header .mod_navigation ul li:nth-child(2) {
    margin-right: 155px
}

#header .mod_navigation a, #header .mod_navigation strong {
    padding-left: 12px;
    font-size: 14px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    font-weight: 300
}

#header .mod_navigation a:hover, #header .mod_navigation a.trail, #header .mod_navigation strong {
    color: #1b1b1a
}

#header .mod_navigation a.trail, #header .mod_navigation strong {
    background-image: url("../images/mainmenu-active.png");
    background-position: left center;
    background-repeat: no-repeat
}

#container {
    text-align: center
}

#main .inside {
    margin-top: 74px;
    margin-bottom: 74px
}

.mod_article {
    width: 992px;
    margin-right: auto;
    margin-left: auto
}

.mod_article.full_width {
    width: 100%
}

.ce_dlh_googlemaps {
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0
}

.ce_dlh_googlemaps .dlh_googlemap {
    width: 100% !important
}

.ce_am_progress_circle .progress-circle {
    height: 123px;
    position: relative;
    margin-bottom: 24px
}

.ce_am_progress_circle .progress-value {
    width: 100%;
    left: 0;
    top: 35px;
    position: absolute;
    text-align: center;
    font: 36px Oswald, sans-serif
}

.ce_am_progress_circle .progress-value .percent {
    font-weight: 300
}

.ce_am_progress_circle .progress-text {
    font: 14px/18px Oswald, sans-serif;
    text-transform: uppercase;
    font-weight: 300
}

.progress-circles {
    padding-top: 14px;
    text-align: center
}

.progress-circles-inner {
    display: inline-block
}

.progress-circles-inner:after {
    content: "";
    display: table;
    clear: both
}

.progress-circles-inner > * {
    width: 166px;
    float: left
}

.section-header {
    margin-bottom: 74px
}

.section-header p {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300
}

.section-text {
    width: 724px;
    margin-right: auto;
    margin-left: auto
}

.subsection-header {
    position: relative;
    margin-top: 65px;
    margin-bottom: 46px;
    padding-top: 26px
}

.subsection-header:before {
    width: 120px;
    height: 1px;
    left: 50%;
    top: 0;
    position: absolute;
    display: block;
    margin-left: -60px;
    background-color: #7d7b7b;
    content: ""
}

.logo-gallery {
    margin-bottom: 50px;
    text-align: center
}

.logo-gallery ul {
    display: inline-block
}

.logo-gallery ul li {
    margin-right: 22px;
    margin-left: 22px
}

.link-button {
    padding-top: 70px;
    text-align: center
}

.link-button a {
    display: inline-block;
    padding: 10px 45px;
    background-color: #b8bac3;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300
}

.link-button a:hover {
    background-color: #7a01a2
}

.service-box figure {
    margin-bottom: 26px
}

.service-box h3 {
    margin-bottom: 9px;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 300
}

#footer {
    width: 100%;
    height: 230px;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    font-size: 12px;
    line-height: 18px
}

#footer a {
    text-decoration: underline;
    color: #fff
}

#footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

#footer ul li {
    margin: 0;
    padding: 0
}

#footer .top_link {
    width: 35px;
    height: 18px;
    margin-right: auto;
    margin-left: auto;
    background-image: url("../images/footer-top.png");
    cursor: pointer
}

#footer .inner {
    height: 212px;
    padding-top: 53px;
    background-image: url("../images/footer-bg.jpg");
    border-bottom: 8px solid #7a01a2;
    color: #fff
}

#footer .boxes, #footer .copyright {
    width: 992px;
    margin-right: auto;
    margin-left: auto
}

#footer .boxes {
    height: 99px;
    position: relative
}

#footer .box {
    width: 25%;
    float: left;
    padding-right: 10px;
    padding-left: 10px
}

#footer .address-contact-boxes {
    left: 25%;
    right: 25%;
    top: 0;
    position: absolute;
    z-index: 1
}

#footer .address-contact-boxes .box {
    width: 50%
}

#footer .address-contact-boxes:after, #footer .links-social-boxes:after {
    content: "";
    display: table;
    clear: both
}

#footer .social-box {
    float: right
}

#footer .social-box:after {
    content: "";
    display: table;
    clear: both
}

#footer .social-box ul {
    float: right
}

#footer .social-box ul li {
    float: left;
    margin-left: 25px
}

#footer .social-box a {
    height: 19px;
    display: block;
    background-repeat: no-repeat
}

#footer .social-box a.facebook {
    width: 10px;
    background-image: url("../images/footer-facebook.png")
}

#footer .social-box a.instagram {
    width: 19px;
    background-image: url("../images/footer-instagram.png")
}

#footer .copyright > div {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 17px;
    text-align: center;
    border-top: 1px dotted #696c78;
    color: #75767e
}

#subfooter {
    padding-top: 75px;
    padding-bottom: 115px;
    background: #fff url("../images/article-divider.png") center top no-repeat
}

#subfooter .inside {
    padding-top: 26px
}

#subfooter .inside:before {
    width: 120px;
    height: 1px;
    left: 50%;
    top: 0;
    position: absolute;
    display: block;
    margin-left: -60px;
    background-color: #7d7b7b;
    content: ""
}

#subfooter .mod_article {
    text-align: center
}

#subfooter .content-gallery {
    padding-right: 0;
    padding-left: 0
}

#subfooter .content-gallery ul li {
    padding-right: 10px;
    padding-left: 10px
}

#subfooter .content-gallery img:not(:hover) {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
}

#subfooter h4 {
    margin-bottom: 48px;
    font: 22px/28px Open Sans, Arial, sans-serif;
    font-weight: 300
}

.formbody {
}

.ce_am_person {
    width: 330px;
    height: 480px
}

.ce_am_person.center-person {
    margin-left: 330px
}

.ce_am_person .name {
    margin-bottom: 7px;
    font: 18px/22px Oswald, sans-serif;
    text-transform: uppercase
}

.ce_am_person .position {
    padding-right: 50px;
    padding-left: 50px;
    font-size: 14px;
    line-height: 18px;
    font-style: italic
}

.ce_am_person .image {
    position: relative;
    margin-bottom: 30px
}

.ce_am_person .image .image-hover {
    left: 0;
    top: 0;
    position: absolute;
    display: none
}

.ce_am_person .image .social-links {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    display: none;
    background-color: rgba(0, 0, 0, .25);
    border-radius: 50%
}

.ce_am_person .image .social-links-inner {
    width: 100%;
    height: 32px;
    left: 0;
    top: 50%;
    position: absolute;
    margin-top: -16px;
    text-align: center
}

.ce_am_person .image .social-links ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none
}

.ce_am_person .image .social-links ul li {
    float: left;
    margin: 0 7px;
    padding: 0
}

.ce_am_person .image .social-links a {
    width: 32px;
    height: 32px;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid #fff;
    border-radius: 50%;
    text-decoration: none;
    color: #fff
}

.ce_am_person .image .social-links a.email {
    background-image: url("../images/person-email.png")
}

.ce_am_person .image .social-links a.linkedin {
    background-image: url("../images/person-linkedin.png")
}

.ce_am_person .image .social-links a.skype {
    background-image: url("../images/person-skype.png")
}

.ce_am_person .image:hover .image-hover, .ce_am_person .image:hover .social-links {
    display: block
}

.ce_am_person .image img {
    border-radius: 50%
}

body.layout-homepage *[class*="ce_"], body.layout-homepage *[class*="mod_"] {
    margin-right: 0;
    margin-left: 0
}

body.layout-homepage #wrapper {
    padding-top: 0
}

body.layout-homepage .mod_article {
    width: auto;
    position: relative;
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d
}

body.layout-homepage .mod_article .inner {
    width: 992px;
    top: 50%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

body.layout-homepage #header {
    display: none
}

body.layout-homepage #main .inside {
    margin-top: 0;
    margin-bottom: 0
}

body.layout-homepage #subfooter {
    padding-top: 57px;
    padding-bottom: 120px
}

body.layout-homepage #subfooter .inside {
    padding-top: 0
}

body.layout-homepage #subfooter .inside:before {
    display: none
}

body.layout-homepage #subfooter .mod_article {
    width: 992px;
    margin-right: auto;
    margin-left: auto
}

body.layout-homepage #subfooter .content-image {
    margin-bottom: 22px
}

body.layout-homepage #subfooter .content-text {
    margin-right: 0;
    margin-left: 0;
    padding-right: 40px;
    padding-left: 40px
}

body.layout-homepage #subfooter .content-text blockquote {
    margin: 0 0 21px;
    padding: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300
}

body.layout-homepage #subfooter .content-text p {
    margin-bottom: 0
}

body.layout-homepage #subfooter .content-text > .rte > p:last-child {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300
}

body.layout-homepage #subfooter .slider-prev, body.layout-homepage #subfooter .slider-next {
    display: none
}

body.layout-homepage #subfooter .slider-control {
    height: auto;
    padding-top: 34px;
    text-align: center
}

body.layout-homepage #subfooter .slider-menu {
    width: auto;
    position: static;
    display: inline-block;
    margin-left: 0
}

body.layout-homepage #subfooter .slider-menu b {
    width: 15px;
    height: 15px;
    float: left;
    display: block;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #b8bac3;
    border-radius: 50%;
    text-indent: -9999px
}

body.layout-homepage #subfooter .slider-menu b.active {
    background-color: #000
}

.homepage-splash {
    min-height: 900px;
    /*background-image: url("../images/home-intro-bg.jpg")*/
}

.homepage-splash .content-text {
    bottom: 0;
    left: 50%;
    position: absolute;
    margin-bottom: 63px;
    padding-left: 10px;
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
    margin-left: 42px !important
}

.homepage-splash .content-text:before {
    left: 0;
    top: 0;
    position: absolute;
    content: "*"
}

.homepage-splash .content-text p {
    margin-bottom: 0
}

.homepage-splash .content-text a {
    text-decoration: underline;
    color: #7a01a2
}

.homepage-intro {
    min-height: 530px;
    background-color: #fff
}

body.layout-homepage .homepage-intro {
    display: flex;
    justify-content: center;
    align-items: center
}

body.layout-homepage .homepage-intro .inner {
    position: static;
    top: 0;
    transform: none
}

.homepage-intro:before {
    width: 840px;
    height: 18px;
    left: 50%;
    top: 0;
    position: absolute;
    display: block;
    margin-left: -420px;
    background-image: url("../images/article-divider.png");
    content: "";
    z-index: 1
}

.homepage-intro h1 {
    margin-bottom: 9px;
    font-size: 91px;
    line-height: 1
}

.homepage-intro h2 {
    font: 48px/1.4 Oswald, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase
}

.homepage-intro .content-text {
    position: relative;
    background: #fff;
    z-index: 1
}

.homepage-intro .content-text:first-child {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end
}

.homepage-intro .content-text img {
    max-width: 100px;
    margin-left: 38px
}

.ce_am_project_teaser {
    padding-right: 0;
    padding-left: 0
}

.ce_am_project_teaser a {
    min-height: 100vh;
    position: relative;
    display: block;
    padding-top: 130px;
    text-decoration: none;
    color: #fff
}

.ce_am_project_teaser a > .teaser-content {
    position: relative;
    z-index: 2
}

.ce_am_project_teaser a > .teaser-image {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    z-index: 0
}

.ce_am_project_teaser a > .teaser-image--hover {
    opacity: 0;
    transition: opacity .4s;
    z-index: 1
}

.ce_am_project_teaser a.active > .teaser-image--hover {
    opacity: 1
}

.ce_am_project_teaser h1 {
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 37px;
    font-size: 120px;
    line-height: 1;
    color: #fff;
    text-transform: none
}

.ce_am_project_teaser h1:after {
    width: 60px;
    height: 3px;
    bottom: 0;
    left: 50%;
    position: absolute;
    display: block;
    margin-left: -30px;
    background-color: #fff;
    content: ""
}

.ce_am_project_teaser h4 {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 369px;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 300
}

.ce_am_project_teaser h4:after {
    width: 1px;
    height: 331px;
    bottom: 0;
    left: 50%;
    position: absolute;
    display: block;
    background-color: #fff;
    content: ""
}

.ce_am_project_teaser .info-text {
    position: relative;
    margin-bottom: 28px;
    padding-top: 81px;
    padding-bottom: 155px
}

.ce_am_project_teaser .info-text:before {
    width: 1px;
    height: 68px;
    left: 50%;
    top: 0;
    position: absolute;
    display: block;
    background-color: #fff;
    content: ""
}

.ce_am_project_teaser .info-text:after {
    width: 1px;
    height: 140px;
    bottom: 0;
    left: 50%;
    position: absolute;
    display: block;
    background-color: #fff;
    content: ""
}

.ce_am_project_teaser .info-text p {
    margin-bottom: 16px
}

.ce_am_project_teaser .info-text p:last-child {
    margin-bottom: 0
}

.homepage-projects-link {
    margin-bottom: 78px
}

*[class*="mod_am_memo"] {
    padding-right: 0;
    padding-left: 0
}

.memo .items:after {
    content: "";
    display: table;
    clear: both
}

.memo .item {
    width: 248px;
    height: 248px;
    overflow: hidden;
    float: left;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px
}

.memo .item-inner {
    width: 100%;
    height: 100%
}

.memo .item a {
    display: block;
    text-decoration: none
}

.memo .item.type-image img, .memo .item.type-instagram img {
    display: block
}

.memo .item.type-text .item-inner {
    background-color: #7a01a2;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.memo .item.type-text .text {
    top: 50%;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    font: 36px/44px Oswald, sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.memo .item.type-fact .item-inner, .memo .item.type-twitter .item-inner, .memo .item.type-facebook .item-inner {
    padding-top: 24px;
    padding-right: 18px;
    padding-left: 18px
}

.memo .item.type-fact .item-inner {
    background-color: #26262c;
    color: #fff
}

.memo .item.type-twitter .item-inner {
    background-color: #fff;
    color: #26262c
}

.memo .item.type-facebook .item-inner {
    background-color: #e5e7ef;
    color: #26262c
}

.memo .item.type-instagram .item-inner, .memo .item.type-twitter .item-inner, .memo .item.type-facebook .item-inner {
    position: relative
}

.memo .item.type-instagram .item-inner:before, .memo .item.type-twitter .item-inner:before, .memo .item.type-facebook .item-inner:before {
    width: 21px;
    height: 21px;
    bottom: 18px;
    left: 18px;
    position: absolute;
    display: block;
    background-position: left bottom;
    background-repeat: no-repeat;
    content: ""
}

.memo .item.type-instagram .item-inner:before {
    background-image: url("../images/memo-instagram.png")
}

.memo .item.type-twitter .item-inner:before {
    background-image: url("../images/memo-twitter.png")
}

.memo .item.type-facebook .item-inner:before {
    background-image: url("../images/memo-facebook.png")
}

.memo .item.type-fact .text, .memo .item.type-twitter .text, .memo .item.type-facebook .text {
    height: 162px;
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300
}

.memo .item.type-fact .info, .memo .item.type-twitter .info, .memo .item.type-facebook .info {
    text-align: right;
    font-size: 11px;
    line-height: 14px
}

.page-agentur-intro {
    margin-bottom: 80px;
    font-size: 60px;
    line-height: 72px;
    color: #ccc;
    font-weight: 300
}

.page-agentur-intro p {
    margin-bottom: 0
}

.page-agentur-intro strong {
    color: #777783;
    font-weight: 300
}

.page-agentur-people {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.page-agentur-awards:after {
    content: "";
    display: table;
    clear: both
}

.page-agentur-awards .content-text {
    width: 330px;
    float: left
}

.page-agentur-awards .content-text figure {
    margin-bottom: 25px
}

.page-agentur-awards .content-text h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300
}

.page-agentur-contact .link-button {
    padding-top: 150px
}

.page-contact #main .inside {
    margin-bottom: 0
}

.page-contact #container {
    padding-bottom: 6px
}

.project-list {
    margin-bottom: 185px;
    padding-top: 60px
}

.project-list:after {
    content: "";
    display: table;
    clear: both
}

.project-list.tile-view .ce_am_project_link {
    float: left;
    margin-bottom: 20px
}

.project-list.list-view .ce_am_project_link .list-view {
    display: block
}

.project-list.list-view .ce_am_project_link .tile-view {
    display: none
}

.project-list.tile-view .ce_am_project_link .list-view {
    display: none
}

.project-list.tile-view .ce_am_project_link .tile-view {
    display: block
}

.project-list-header {
    margin-bottom: 28px
}

.project-list-switcher {
    position: relative;
    text-align: center
}

.project-list-switcher:before {
    width: 1px;
    height: 10px;
    left: 50%;
    top: 0;
    position: absolute;
    background-color: #4b4b4f;
    content: "";
    top: 7px
}

.project-list-switcher a {
    width: 27px;
    height: 21px;
    display: inline-block;
    margin-right: 25px;
    margin-left: 25px;
    background-position: center center;
    background-repeat: no-repeat
}

.project-list-switcher a.list-view {
    background-image: url("../images/project-list-view.png")
}

.project-list-switcher a.tile-view {
    background-image: url("../images/project-tile-view.png")
}

.ce_am_project_link a {
    display: block
}

.ce_am_project_link img {
    display: block
}

.ce_am_project_link .list-view, .ce_am_project_link .tile-view {
    position: relative
}

.ce_am_project_link .list-view {
    margin-bottom: 20px
}

.ce_am_project_link .image-hover {
    left: 0;
    top: 0;
    position: absolute;
    display: none
}

.ce_am_project_link a:hover .image-hover {
    display: block
}

.page-project .content-image, .page-project .ce_youtube, .page-project .ce_vimeo {
    margin-bottom: 20px
}

.page-project #wrapper {
    padding-top: 0
}

.page-project #main .inside {
    margin-top: 0
}

.page-project .content-text {
    max-width: 764px;
    margin-right: auto;
    margin-left: auto
}

.page-project .text-decor {
    max-width: 100%;
    position: relative;
    margin-top: 90px;
    margin-bottom: 25px;
    padding-top: 26px;
    font-style: italic
}

.page-project .text-decor:before {
    width: 120px;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: #7d7b7b
}

.page-project .text-decor > * {
    margin-bottom: 0
}

.page-project .text-decor > *:first-child {
    margin-bottom: 5px;
    font: 22px/28px "Open Sans", Arial, sans-serif;
    font-weight: normal;
    font-weight: 300
}

.page-project #subfooter .inside {
    width: 470px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0
}

.page-project #subfooter .inside:before {
    display: none
}

.page-project #subfooter .mod_booknav {
    margin-right: 0;
    margin-left: 0
}

.page-project #subfooter .mod_booknav:after {
    content: "";
    display: table;
    clear: both
}

.page-project #subfooter .mod_booknav .prev {
    float: left
}

.page-project #subfooter .mod_booknav .next {
    float: right
}

.page-project #subfooter .mod_booknav a {
    font: 18px Oswald, sans-serif;
    font-weight: bold;
    text-decoration: none;
    color: #26262c;
    text-transform: uppercase
}

.page-project #subfooter .social-links {
    width: 200px;
    left: 50%;
    top: 0;
    position: absolute;
    margin-left: -100px;
    text-align: center;
    top: 2px
}

.page-project #subfooter .social-links a {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
    background-position: center center;
    background-repeat: no-repeat
}

.page-project #subfooter .social-links a.facebook {
    background-image: url("../images/project-facebook.png")
}

.page-project #subfooter .mod_booknav a:hover {
    color: #7a01a2
}

.project-header {
    width: 100%;
    overflow: visible;
    margin-bottom: 40px
}

.project-details {
    margin-top: 176px;
    margin-bottom: -15px
}

.project-details.has-image {
    width: 992px;
    left: 50%;
    top: 0;
    position: absolute;
    margin-bottom: 0;
    margin-left: -496px
}

.project-title {
    width: 100%;
    height: 107px;
    left: 0;
    top: 100px;
    position: static;
    margin-bottom: 22px;
    padding-top: 10px;
    -webkit-transition: background-color ease-out .5s;
    -moz-transition: background-color ease-out .5s;
    -o-transition: background-color ease-out .5s;
    transition: background-color ease-out .5s
}

.project-title:after {
    width: 100%;
    height: 20px;
    bottom: -20px;
    left: 0;
    position: absolute;
    display: block;
    content: "";
    opacity: 0;
    -webkit-transition: opacity ease-out .5s;
    -moz-transition: opacity ease-out .5s;
    -o-transition: opacity ease-out .5s;
    transition: opacity ease-out .5s; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f3f4+0,ffffff+100&amp;1+0,0+100 */
    background: -moz-linear-gradient(top, rgba(244, 243, 244, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(244, 243, 244, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(244, 243, 244, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f3f4', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
}

.project-title h1 {
    width: 992px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 32px;
    font-size: 60px;
    line-height: 65px;
    text-transform: none
}

.project-title h1:before {
    width: 60px;
    height: 3px;
    bottom: 0;
    left: 50%;
    position: absolute;
    margin-left: -30px;
    background-color: #000;
    content: ""
}

.project-description {
    max-width: 764px;
    margin-right: auto;
    margin-bottom: 17px;
    margin-left: auto;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300
}

.project-info {
    position: relative;
    padding-top: 84px;
    padding-bottom: 31px;
    font-size: 14px;
    font-style: italic;
    font-weight: 300
}

.project-details.has-image .project-info {
    margin-bottom: 40px
}

.project-description, .project-info {
    -webkit-transition: opacity ease-out .5s;
    -moz-transition: opacity ease-out .5s;
    -o-transition: opacity ease-out .5s;
    transition: opacity ease-out .5s;
    opacity: 1
}

.project-info:before {
    width: 1px;
    height: 68px;
    left: 50%;
    top: 0;
    position: absolute;
    background-color: #000;
    content: ""
}

.project-info:after {
    width: 1px;
    height: 15px;
    bottom: 0;
    left: 50%;
    position: absolute;
    background-color: #000;
    content: ""
}

.project-info p {
    margin-bottom: 0
}

.project-header.sticky .project-title {
    position: fixed
}

.project-header.solid .project-title {
    background-color: #f4f3f4
}

.project-header.solid .project-title:after {
    opacity: 1
}

.project-header.sticky .project-details {
    padding-top: 129px
}

.project-header.sticky .project-description, .project-header.sticky .project-info {
    opacity: 0
}

.ce_am_project_header {
    overflow: visible;
    padding-right: 0;
    padding-left: 0
}

.content-image.project-image figure, .ce_youtube.project-image figure, .ce_vimeo.project-image figure, .ce_vimeo .iframe-container, .content-gallery.project-image ul li {
    border: 4px solid #fff
}

.ce_vimeo .iframe-container {
    height: 0;
    position: relative;
    padding-bottom: 56.25%
}

.ce_vimeo .iframe-container iframe {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: none;
}

.ce_youtube iframe {
    border: none;
}

.project-gallery .content-gallery ul li {
    margin-bottom: 20px
}

.page-layout-impressum #container {
    text-align: left
}

.page-layout-impressum .content-text:not(.section-header) {
    overflow: hidden;
    max-width: 764px;
    margin-right: auto;
    margin-left: auto
}

.page-layout-impressum .section-header {
    margin-bottom: 0;
    text-align: center
}

.page-layout-impressum h1 {
    margin-bottom: 17px;
    text-align: center
}

.page-layout-impressum h2 {
    margin-top: 63px;
    margin-bottom: 11px
}

.page-layout-impressum h2 + p {
    margin-top: 17px
}

.page-layout-impressum h3 {
    margin-bottom: 14px;
    font: 16px/28px "Open Sans", Arial, sans-serif;
    font-style: italic;
    font-weight: 300
}

.page-layout-impressum p + h3 {
    margin-top: 30px
}
