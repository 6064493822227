/**
 * mobile_menu extension for Contao Open Source CMS
 *
 * Copyright (C) 2011-2015 Codefog
 *
 * @package mobile_menu
 * @author  Codefog <http://codefog.pl>
 * @author  Kamil Kuzminski <kamil.kuzminski@codefog.pl>
 * @license LGPL
 */

/* Menu */
.mobile_menu {
    position: fixed;
    z-index: 100;
    display: none;
}

.mobile_menu .inner {
    height: 100%;
    background: #000000;
    overflow-y: scroll;
}

/* Menu shadows */
.mobile_menu.active.position_left {
    box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
}

.mobile_menu.active.position_top {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}

.mobile_menu.active.position_right {
    box-shadow: -2px 0 5px 0 rgba(0, 0, 0, 0.5);
}

.mobile_menu.active.position_bottom {
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
}

.mobile_menu.active.no_shadow {
    box-shadow: none !important;
}

/* Trigger */
.mobile_menu_trigger {
    display: none;
}

/* Wrapper */
.mobile_menu_wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}

/* Overlay */
.mobile_menu_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
}

.mobile_menu_overlay.background {
    background: rgba(0,0,0,0.5);
}

/* Navigation */
.mobile_menu li.submenu_hide > ul {
    display: none;
}

.mobile_menu li.submenu_show > ul {
    display: block;
}

/* Hide menu on IE8-9 */
body.ie8 .mobile_menu,
body.ie8 .mobile_menu_trigger,
body.ie9 .mobile_menu,
body.ie9 .mobile_menu_trigger {
    display: none !important;
}