@media screen and (max-width: 767px) {

    /* phones.css */
    body {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 18px
    }

    h1 {
        font-size: 32px;
        line-height: 40px
    }

    .section-header p {
        font-size: 16px;
        line-height: 20px
    }

    .page-layout-impressum h2 {
        margin-top: 33px
    }

    .page-layout-impressum h1 {
        word-break: break-word
    }

    #wrapper {
        padding-top: 56px
    }

    #header {
        height: 56px;
        z-index: 101;
        background-color: #fbfbfb !important
    }

    #header .mod_navigation {
        display: none
    }

    #logo {
        top: 15px;
        margin-left: -12px
    }

    #logo img {
        width: 24px;
        height: 24px
    }

    .mobile_menu_trigger {
        width: 26px;
        height: 13px;
        float: right;
        margin-top: 21px;
        background-image: url("../images/mobile-menu-open.png");
        background-size: 16px 13px
    }

    .mobile_menu_trigger.active {
        width: 19px;
        height: 19px;
        margin-top: 19px;
        background-image: url("../images/mobile-menu-close.png");
        background-size: 19px 19px
    }

    .mobile_menu {
        box-shadow: none !important
    }

    .mobile_menu .inner {
        padding-top: 130px;
        background-color: #f8f8f8
    }

    .mobile_menu .mod_navigation ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-top: 1px solid #edebef;
        border-bottom: 1px solid #ffffff
    }

    .mobile_menu .mod_navigation ul li {
        margin: 0;
        padding: 0;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #edebef
    }

    .mobile_menu .mod_navigation a, .mobile_menu .mod_navigation strong {
        display: block;
        padding-top: 24px;
        padding-bottom: 29px;
        text-align: right;
        font-size: 18px;
        line-height: 30px;
        text-decoration: none;
        color: #b8bac3;
        text-transform: uppercase;
        font-weight: 300
    }

    .mobile_menu .mod_navigation a.trail, .mobile_menu .mod_navigation strong {
        padding-right: 20px;
        background-image: url("../images/mobile-menu-navigation-active.png");
        background-repeat: no-repeat;
        color: #1b1b1a;
        background-position: right 33px;
        background-size: 9px 13px
    }

    #main .inside {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .ce_am_project_header .dzsparallaxer {
        height: auto !important
    }

    .page-project .content-image, .page-project .ce_youtube, .page-project .ce_vimeo {
        margin-bottom: 20px
    }

    .page-project .dzsparallaxer {
        background: transparent
    }

    .project-info {
        display: none
    }

    .page-project .dzsparallaxer .dzsparallaxer--target {
        display: none
    }

    .project-header {
        margin-bottom: 20px
    }

    .project-list {
        margin-bottom: 30px;
        padding-top: 30px
    }

    .project-list.tile-view .ce_am_project_link {
        width: 100%;
        float: none;
        margin-bottom: 10px
    }

    .project-info:before {
        height: 34px
    }

    .project-info {
        padding-top: 42px;
        padding-bottom: 23px
    }

    .ce_am_project_link .list-view {
        margin-bottom: 10px
    }

    .section-header {
        margin-bottom: 37px
    }

    .subsection-header {
        margin-top: 26px;
        font-size: 16px;
        line-height: 20px
    }

    .service-box {
        margin-bottom: 20px
    }

    .service-box:last-child {
        margin-bottom: 0
    }

    .service-box h3 {
        font-size: 20px;
        line-height: 22px
    }

    .service-box figure {
        margin-bottom: 13px
    }

    .page-agentur #subfooter {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .page-agentur #main .mod_article {
        margin-top: 60px
    }

    .page-agentur-awards .content-text h3 {
        font-size: 16px;
        line-height: 18px
    }

    .page-agentur-awards img {
        width: 49px
    }

    .link-button, .page-agentur-contact .link-button {
        padding-top: 35px;
        padding-bottom: 35px;
        font-size: 14px
    }

    .link-button a {
        padding: 6px 18px
    }

    .page-agentur-intro {
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 46px
    }

    .ce_am_person, .page-agentur-awards .content-text {
        width: 100%;
        height: auto;
        float: none;
        margin-bottom: 40px
    }

    .ce_am_person .image:hover .image-hover {
        width: 100%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%)
    }

    .ce_am_person .image .social-links {
        width: 235px;
        height: 235px;
        left: 50%;
        transform: translateX(-50%)
    }

    .ce_am_person .image .social-links ul li {
        margin-right: 2px;
        margin-left: 2px
    }

    .ce_am_progress_circle .progress-text {
        font-size: 16px;
        line-height: 20px
    }

    .section-text {
        width: auto
    }

    .progress-circles {
        padding-top: 0
    }

    .mod_am_memo .items {
        width: 248px
    }

    .progress-circles-inner > * {
        width: 100%;
        float: none;
        margin-bottom: 50px
    }

    .page-agentur-awards .content-text:last-child {
        margin-bottom: 0
    }

    .page-project #subfooter .inside {
        width: auto;
        padding-right: 20px;
        padding-left: 20px
    }

    .page-project #subfooter .social-links a {
        width: 15px;
        height: 15px;
        background-size: contain
    }

    .page-project #subfooter .mod_booknav a {
        font-size: 12px
    }

    .project-title {
        height: auto;
        top: 56px;
        margin-bottom: 11px
    }

    .project-title h1 {
        padding-bottom: 20px;
        font-size: 40px;
        line-height: 45px
    }

    .project-details {
        margin-top: 100px;
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px
    }

    .project-details.has-image {
        width: 100%;
        left: 0;
        position: static;
        margin-left: 0
    }

    .project-description {
        font-size: 16px;
        line-height: 20px
    }

    #subfooter, body.layout-homepage #subfooter {
        padding-top: 40px;
        padding-bottom: 60px
    }

    #subfooter h4 {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px
    }

    #subfooter .content-gallery ul li {
        width: 100%;
        padding-bottom: 10px
    }

    body.layout-homepage #subfooter .slider-menu b {
        width: 12px;
        height: 12px
    }

    body.layout-homepage #subfooter img {
        width: 174px
    }

    body.layout-homepage #subfooter .content-text blockquote {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px
    }

    body.layout-homepage #subfooter .content-text {
        padding-right: 20px;
        padding-left: 20px
    }

    body.layout-homepage #subfooter .content-text > p:last-child {
        font-size: 12px
    }

    body.layout-homepage #subfooter .slider-control {
        padding-top: 10px
    }

    .homepage-splash {
        min-height: 500px
    }

    .homepage-splash img {
        width: 243px
    }

    .homepage-splash .content-text {
        margin-bottom: 16px;
        padding-left: 5px;
        font-size: 6px;
        line-height: 8px;
        margin-left: 22px !important
    }

    .homepage-intro h1 {
        font-size: 24px;
        line-height: 35px
    }

    .homepage-intro h2 {
        font-size: 20px
    }

    .homepage-intro .content-text img {
        max-width: 50px;
        margin-left: 30px
    }

    .ce_am_project_teaser a {
        font-size: 13px;
        line-height: 1
    }

    .ce_am_project_teaser .info-text {
        margin-bottom: 14px;
        padding-top: 40px;
        padding-bottom: 78px
    }

    .ce_am_project_teaser .info-text:before {
        height: 34px
    }

    .ce_am_project_teaser .info-text:after {
        height: 64px
    }

    .ce_am_project_teaser h1 {
        margin-bottom: 17px;
        padding-bottom: 18px;
        font-size: 60px
    }

    .ce_am_project_teaser h1:after {
        width: 30px;
        height: 2px;
        margin-left: -15px
    }

    .ce_am_project_teaser h4 {
        padding-bottom: 165px
    }

    .ce_am_project_teaser h4:after {
        height: 149px
    }

    #footer {
        height: auto;
        position: static;
        margin-top: -18px;
        font-size: 12px;
        line-height: 18px
    }

    #footer .inner {
        height: auto;
        padding-top: 20px;
        padding-bottom: 10px;
        border-bottom-width: 5px
    }

    #footer .top_link {
        width: 33px;
        height: 17px;
        background-image: url("../images/mobile-footer-top.png");
        background-size: 33px 17px
    }

    #footer .boxes {
        height: auto
    }

    #footer .boxes:after {
        content: "";
        display: table;
        clear: both
    }

    #footer .box {
        width: auto;
        float: none;
        margin-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px
    }

    #footer .address-contact-boxes {
        width: 55%;
        position: static;
        float: left
    }

    #footer .address-contact-boxes .box {
        width: auto
    }

    #footer .links-social-boxes {
        width: 45%;
        float: right;
        text-align: right
    }

    #footer .social-box ul li {
        margin-left: 12px
    }

    #footer .social-box a {
        height: 14px;
        background-size: contain
    }

    #footer .copyright {
        width: 200px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0
    }

    #footer .copyright > div {
        padding-top: 12px
    }
}
