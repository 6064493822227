@media screen and (max-width: 991px) {

    /* tablets.css */
    .mod_article.full_width {
        padding-right: 0;
        padding-left: 0
    }

    #header .inside, #footer .boxes, #footer .copyright {
        width: 100%
    }

    #header .inside, .mod_article, #footer .boxes, body.layout-homepage #subfooter .mod_article {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px
    }

    #header {
        background-color: #fbfbfb
    }

    #footer {
        height: 230px
    }

    .logo-gallery ul li {
        float: none;
        display: inline-block;
        margin-bottom: 20px
    }

    .logo-gallery {
        margin-bottom: 0
    }

    .ce_dlh_googlemaps {
        height: 35vh !important
    }

    .ce_dlh_googlemaps .dlh_googlemap {
        height: 100% !important
    }

    .homepage-splash img {
        width: 500px
    }

    .homepage-splash .content-text {
        margin-bottom: 45px
    }

    .homepage-intro h1 {
        font-size: 60px
    }

    .homepage-intro h2 {
        font-size: 36px
    }

    .homepage-projects-link {
        margin-top: 0;
        margin-bottom: 0
    }
}
