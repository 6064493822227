/*!
 * This file is part of Contao.
 *
 * (c) Leo Feyer
 *
 * @license LGPL-3.0-or-later
 */

/**
 * Apply the holy grail CSS layout if the screen is at least 768 pixel wide,
 * otherwise display all columns underneath each other
 *
 * @see http://www.alistapart.com/articles/holygrail
 */
@media (max-width: 767px) {
    #wrapper {
        margin: 0;
        width: auto;
    }

    #container {
        padding-left: 0;
        padding-right: 0;
    }

    #main, #left, #right {
        float: none;
        width: auto;
    }

    #left {
        right: 0;
        margin-left: 0;
    }

    #right {
        margin-right: 0;
    }
}

/**
 * Flexible images
 *
 * @see http://webdesignerwall.com/tutorials/responsive-design-with-css3-media-queries
 */
img {
    max-width: 100%;
    height: auto;
}

.ie7 img {
    -ms-interpolation-mode: bicubic;
}

.ie8 img {
    width: auto; /* see #5789 */
}

/**
 * Responsive audio (see #441)
 */
.audio_container audio {
    max-width: 100%;
}

/**
 * Responsive videos (see #1348)
 */
.video_container video {
    max-width: 100%;
    height: auto;
}

.responsive {
    position: relative;
    height: 0;
}

.responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.responsive.ratio-169 {
    padding-bottom: 56.25%;
}

.responsive.ratio-1610 {
    padding-bottom: 62.5%;
}

.responsive.ratio-219 {
    padding-bottom: 42.8571%;
}

.responsive.ratio-43 {
    padding-bottom: 75%;
}

.responsive.ratio-32 {
    padding-bottom: 66.6666%;
}
