@media screen and (max-width: 1024px) {

    /* small-desktops.css */
    body {
        margin-bottom: 212px
    }

    body.layout-homepage .mod_article {
        padding-right: 0;
        padding-left: 0
    }

    body.layout-homepage .mod_article .inner {
        width: auto
    }

    .homepage-projects-link {
        margin-top: 24px;
        margin-bottom: 48px
    }

    #logo {
        left: 50%;
        margin-left: -24px
    }

    .project-list {
        margin-bottom: 60px
    }

    .project-list.tile-view .ce_am_project_link {
        width: 50%
    }

    .logo-gallery ul li {
        margin-right: 10px;
        margin-left: 10px
    }

    .link-button {
        padding-top: 24px
    }

    .project-header {
        padding-right: 0;
        padding-left: 0
    }

    .project-title h1 {
        width: auto
    }

    .ce_am_person, .page-agentur-awards .content-text {
        width: 33.333%
    }

    .ce_am_person.center-person {
        float: none;
        margin-right: auto;
        margin-left: auto
    }

    .mod_am_memo .items {
        width: 744px;
        margin-right: auto;
        margin-left: auto
    }

    #subfooter .content-gallery ul li {
        width: 33%
    }

    #footer .copyright {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px
    }

    #footer .copyright > div {
        width: 100%
    }
}
